<template>
    <div class="add">
      <!-- 健康档案 -->
      <div class="header">
        <template>
          <el-menu :default-active="$route.path" mode="horizontal" @select="handleSelect" :router="true">
            <el-menu-item :index="item.path" v-for="item in addMenuList" :key="item.id">{{ item.title }}</el-menu-item>
          </el-menu>
          <router-view></router-view>
        </template>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeName: "first",
        addMenuList: [{ id: 1, title: '用户信息', name: 'first', path: '/home/healthRecords/userInfo' }, { id: 2, title: '心理健康记录表', name: 'second',path:'/home/healthRecords/record' }]
      };
    },
    methods: {
      // handleClick(tab, event) {
      //   console.log(tab.name);
      //   if (tab.name === 'first') {
      //     // this.activeName = 'first';
      //     this.$router.push({
      //       path:'/home/healthRecords/userInfo',
      //       query: {
      //         id: this.$route.query.id
      //       }
      //     })
      //   } else if (tab.name === 'second') {
      //     // this.activeName = 'second'
      //     this.$router.push({
      //       path:'/home/healthRecords/record',
      //       query: {
      //         id: this.$route.query.id
      //       }
      //     })
      //   } 
      // },
      handleSelect(key, keyPath) {
        // console.log(this.$route.path)
        // console.log(key, keyPath);
      }
    },
    created() {
      // console.log(this.$route.path)
      if (this.$route.path === '/home/healthRecords/userInfo') {
        this.activeName = 'first'
      } else if (this.$route.path === '/home/healthRecords/record') {
        this.activeName = 'second'
      } 
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .add {
    padding: 0 35px;
    padding-top: 10px;
    box-sizing: border-box;
    height: 100%;
  
    .header {
      ::v-deep.el-tabs__header {
        margin: 0 !important;
      }
      .el-menu{
        position:sticky;
        top: 0;
        z-index: 2;
      }
      .el-menu--horizontal > .el-menu-item{
        padding: 0;
        margin-right: 45px;
      }
    }
  
    ::v-deep.el-tabs__header.is-top {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
    }
  
  }
  </style>